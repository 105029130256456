import { useMutation } from "@apollo/client";
import { Button, Dialog, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React from "react";
import _ from "lodash";
import { DateTime } from "luxon";
import { ReactElement, useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CREATE_BUDGET_SECTION,
  DELETE_BUDGET_SECTION,
  UPDATE_BUDGET_SECTION,
} from "../../graphql/mutations/budgetSection";
import { BUDGET_SECTIONS } from "../../graphql/queries/budgetSections";
import { IBudget, IBudgetSection } from "../../types/types";
import { alreadyIncludedCategories } from "../../utils/budgetUtils";
import { graphQlError } from "../../utils/utils";
import { Notifications } from "../common/notifications";
import NewBudgetSectionForm from "../forms/NewBudgetSectionForm";
import { UserContext } from "../WithUserContext";
import BudgetSection from "./BudgetSection";

interface Props {
  selectedDate: DateTime;
  budgetSections: IBudgetSection[];
}

export default function BudgetSections({ budgetSections, selectedDate }: Props): ReactElement {
  const userData = useContext(UserContext);
  const { t } = useTranslation();

  const currentBudget = _.first(userData?.budget_users_connectors)?.budget as IBudget;

  const [budgetSection, setBudgetSection] = useState<IBudgetSection | null>(null);

  const [createBudgetSection] = useMutation(CREATE_BUDGET_SECTION, {
    refetchQueries: [
      {
        query: BUDGET_SECTIONS,
        variables: {
          budgetId: currentBudget,
          periodStart: selectedDate.startOf("month"),
          periodEnd: selectedDate.endOf("month"),
        },
      },
    ],
  });

  const [updateBudgetSection] = useMutation(UPDATE_BUDGET_SECTION);
  const [deleteBudgetSection] = useMutation(DELETE_BUDGET_SECTION);

  const openFormDialog = useCallback(() => {
    setBudgetSection({
      section_name: "",
      order_number: 0,
    } as IBudgetSection);
  }, []);

  const closeFormDialog = useCallback(() => {
    setBudgetSection(null);
  }, []);

  const onChange = useCallback(
    (key: string, value: any) => {
      setBudgetSection(_.extend({}, budgetSection, { [key]: value }));
    },
    [budgetSection]
  );

  const onDelete = useCallback(
    (income: IBudgetSection) => {
      return deleteBudgetSection({
        variables: { id: income.id },
        update(cache) {
          const normalizedId = cache.identify({ id: income.id, __typename: "budget_sections" });
          cache.evict({ id: normalizedId });
          cache.gc();
        },
      })
        .then(() => {
          Notifications?.show({
            message: t("messages.budget_section_removed"),
            intent: Intent.WARNING,
          });
        })
        .catch(graphQlError);
    },
    [deleteBudgetSection]
  );

  const openEditFormDialog = useCallback((income: IBudgetSection) => {
    setBudgetSection(income);
  }, []);

  const onApply = useCallback(
    (income: IBudgetSection) => {
      return (
        !income.id
          ? createBudgetSection({
              variables: {
                object: {
                  ..._.omit(income, ["__typename"]),
                  budget_id: currentBudget,
                  user_id: userData?.user_id,
                },
              },
            })
          : updateBudgetSection({
              variables: {
                ..._.omit(income, ["__typename"]),
                budget_id: currentBudget,
                user_id: userData?.user_id,
              },
            })
      ).then(closeFormDialog);
    },
    [createBudgetSection, updateBudgetSection, currentBudget, userData, closeFormDialog]
  );

  const includedCategories = useMemo(
    () => alreadyIncludedCategories(budgetSections, selectedDate),
    [budgetSections, selectedDate]
  );

  return (
    <div>
      <div className="p-1 my-4">
        <Button icon={IconNames.ADD} onClick={openFormDialog}>
          {t("actions.add_section")}
        </Button>
        <Dialog
          isOpen={budgetSection !== null}
          onClose={closeFormDialog}
          title={
            _.isUndefined(budgetSection?.id)
              ? t("actions.add_section")
              : t("actions.editing_section", { sectionName: budgetSection?.section_name })
          }
        >
          <NewBudgetSectionForm
            budgetSection={budgetSection as IBudgetSection}
            onChange={onChange}
            onCancel={closeFormDialog}
            onApply={onApply}
          />
        </Dialog>
      </div>
      {_.map(budgetSections, (budgetSection) => (
        <BudgetSection
          budgetId={currentBudget.id as number}
          budgetSection={budgetSection}
          key={budgetSection.id}
          onDelete={onDelete}
          onEdit={openEditFormDialog}
          includedCategories={includedCategories}
          selectedDate={selectedDate}
        />
      ))}
    </div>
  );
}
