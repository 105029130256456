import { HTMLTable } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import _ from "lodash";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import React from "react";
import { IIncome } from "../../types/types";
import { ConfirmDialog } from "../common/ConfirmDialog";
import MoneyValue from "../common/MoneyValue";
import Responsive from "../common/Responsive";
import Income from "./Income";
import MobileIncome from "./MobileIncome";
import { DateTime } from "luxon";

const IncomesList = ({
  incomes,
  onEdit,
  onDelete,
  selectedDate,
  totalIncome,
  totalRealIncome,
}: {
  incomes: IIncome[];
  selectedDate: DateTime;
  onEdit: (income: IIncome) => void;
  onDelete: (income: IIncome) => void;
  totalIncome: number;
  totalRealIncome: number;
}) => {
  const [incomeToDelete, setIncomeToDelete] = useState<IIncome | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const { t } = useTranslation();

  const openDeleteDialog = useCallback(
    (income: IIncome) => () => {
      setDeleteDialogOpen(true);
      setIncomeToDelete(income);
    },
    []
  );

  const closeDeleteDialog = useCallback(() => {
    setDeleteDialogOpen(false);
    setIncomeToDelete(null);
  }, []);

  return (
    <>
      <ConfirmDialog
        icon={IconNames.WARNING_SIGN}
        title={t("warnings.delete_income_warning")}
        message={
          <div
            dangerouslySetInnerHTML={{
              __html: t("warnings.remove_income_confirmation", {
                name: incomeToDelete?.name,
                value: incomeToDelete?.value,
              }),
            }}
          />
        }
        onCancel={closeDeleteDialog}
        isOpen={deleteDialogOpen}
        onApply={() => {
          incomeToDelete && onDelete(incomeToDelete);
          closeDeleteDialog();
        }}
      />
      <Responsive displayIn={["Mobile"]}>
        {_.map(incomes, (income) => (
          <MobileIncome
            key={income.id}
            income={income}
            onEdit={onEdit}
            openDeleteDialog={openDeleteDialog(income)}
          />
        ))}
      </Responsive>
      <Responsive displayIn={["Tablet", "Laptop"]}>
        <HTMLTable
          className="budget-table"
          style={{ width: "100%", tableLayout: "fixed" }}
          bordered
          compact
          interactive
          striped
        >
          <thead>
            <tr>
              <th className="budget-table-no">{t("labels.no")}</th>
              <th className="budget-table-name">{t("labels.name")}</th>
              <th className="budget-table-amount">{t("labels.amount")}</th>
              <th className="budget-table-amount">{t("labels.actual_amount")}</th>
              <th className="budget-table-actions" />
            </tr>
          </thead>
          <tbody>
            {_.map(incomes, (income, idx) => (
              <Income
                income={income}
                key={idx}
                index={idx + 1}
                selectedDate={selectedDate}
                openDeleteDialog={openDeleteDialog(income)}
                onEdit={onEdit}
              />
            ))}
            <IncomesSummaryRow totalIncome={totalIncome} totalRealIncome={totalRealIncome} />
          </tbody>
        </HTMLTable>
      </Responsive>
    </>
  );
};

interface IIncomesSummaryRow {
  totalIncome: number;
  totalRealIncome: number;
}

export const IncomesSummaryRow = ({ totalIncome, totalRealIncome }: IIncomesSummaryRow) => {
  const { t } = useTranslation();
  return (
    <tr>
      <td />
      <td>{t("labels.total")}: </td>
      <td>
        <MoneyValue value={totalIncome} />
      </td>
      <td>
        <MoneyValue value={totalRealIncome} />
      </td>
      <td>
        <MoneyValue value={totalRealIncome - totalIncome} />
      </td>
    </tr>
  );
};

export default IncomesList;
