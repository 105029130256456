import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { useAuth0, User } from "@auth0/auth0-react";
import { Spinner, SpinnerSize } from "@blueprintjs/core";
import { App as CapApp } from "@capacitor/app";
import { Browser } from "@capacitor/browser";
import _ from "lodash";
import React, { ReactNode, useCallback, useEffect, PropsWithChildren } from "react";
import { graphqlUri } from "../settings";
import { useTokenStore } from "../stores/useTokenStore";
import PublicRoutes from "./PublicRoutes";
import WithUserContext from "./WithUserContext";

const httpLink = new HttpLink({
  uri: graphqlUri,
});

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined;

const getApolloClient = (token: string | null) => {
  if (apolloClient && !_.isEmpty(token)) return apolloClient;
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
      },
    },
  });
  return apolloClient;
};

const WithToken = ({ children }: { children: ReactNode }) => {
  const { getAccessTokenSilently, isLoading, user, handleRedirectCallback, isAuthenticated } =
    useAuth0();
  const { token, setToken } = useTokenStore((state) => ({
    ...state,
  }));

  useEffect(() => {
    // Handle the 'appUrlOpen' event and call `handleRedirectCallback`
    CapApp.addListener("appUrlOpen", async ({ url }) => {
      if (url.includes("state") && (url.includes("code") || url.includes("error"))) {
        await handleRedirectCallback(url);
        _.delay(() => {
          getToken();
        }, 500);
      }
      // No-op on Android
      await Browser.close();
    });
  }, [handleRedirectCallback]);

  const getToken = useCallback(async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      setToken(accessToken);
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    getToken();
  }, []);

  return (
    <React.Fragment>
      {_.isEmpty(token) && isLoading ? (
        <Spinner size={SpinnerSize.LARGE} />
      ) : !isAuthenticated ? (
        <PublicRoutes />
      ) : (
        token && (
          <Provider token={token} user={user}>
            {children}
          </Provider>
        )
      )}
    </React.Fragment>
  );
};

const Provider = ({
  token,
  user,
  children,
}: PropsWithChildren<{ user: User | undefined; token: string }>) => {
  return (
    <ApolloProvider client={getApolloClient(token)}>
      <WithUserContext authUser={user}>{children}</WithUserContext>
    </ApolloProvider>
  );
};

export default WithToken;
