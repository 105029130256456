import _ from "lodash";
import React, { ReactNode, useContext } from "react";
import { IBudget } from "../types/types";
import CreateBudget from "./budget/CreateBudget";
import Subscriptions from "./Subscriptions";
import { useSubscriptionData } from "./useSubscriptionData";
import { UserContext } from "./WithUserContext";
import { useLocation } from "react-router-dom";
import ContentWithFooter from "./layouts/ContentWithFooter";

interface Props {
  children: ReactNode;
}

export const BudgetContext = React.createContext<IBudget | undefined>(undefined);

const WithBudgetContext = ({ children }: Props) => {
  const userData = useContext(UserContext);
  const { hasValidSubscription } = useSubscriptionData();
  const currentBudget = _.first(userData?.budget_users_connectors)?.budget;

  const location = useLocation();
  switch (location.pathname) {
    case "/contact":
    case "/roadmap":
    case "/privacy-policy":
    case "/settings":
      return null;
  }

  return currentBudget && hasValidSubscription ? (
    <BudgetContext.Provider value={currentBudget}>{children}</BudgetContext.Provider>
  ) : (
    <ContentWithFooter>
      {hasValidSubscription ? <CreateBudget /> : <Subscriptions />}
    </ContentWithFooter>
  );
};

export default WithBudgetContext;
