import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  ButtonGroup,
  DefaultPopoverTargetHTMLProps,
  Dialog,
  DialogBody,
  DialogFooter,
  Intent,
  MenuItem,
  PopoverProps,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { ItemModifiers, Select } from "@blueprintjs/select";
import _ from "lodash";
import { default as React, useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCreateAccount } from "../../graphql/hooks/account";
import { CREATE_CATEGORIES } from "../../graphql/mutations/category";
import { ACCOUNTS_LIST } from "../../graphql/queries/account";
import { CATEGORIES } from "../../graphql/queries/categories";
import { IAccount } from "../../types/types";
import { graphQlError } from "../../utils/utils";
import EntityIcon from "../common/EntityIcon";
import { UserContext } from "../WithUserContext";
import WithLoadingSpinner from "./WithLoadingSpinner";
import { BudgetContext } from "../WithBudgetContext";

const filterAccounts = (query: string, account: IAccount) => {
  return account.name.toLowerCase().indexOf(query.toLowerCase()) >= 0;
};

const AccountSelectItem = ({
  account,
  handleClick,
  active,
}: {
  account: Partial<IAccount>;
  active: boolean;
  handleClick: (event: React.MouseEvent) => void;
}) => {
  return (
    <MenuItem
      active={active}
      onClick={handleClick}
      key={account.id}
      text={
        <div className="flex flex-row items-center">
          <EntityIcon icon={account.icon} type="account" />
          <span className="inline-block ml-2">{account.name}</span>
        </div>
      }
    />
  );
};

const createItem = (name: string) => {
  return {
    name,
    initial_amount: 0,
  };
};

const renderCreateItemOption = (
  query: string,
  active: boolean,
  handleClick: (event: React.MouseEvent) => void
) => {
  return (
    <MenuItem
      icon="add"
      text={`Create "${query}"`}
      active={active}
      onClick={handleClick}
      shouldDismissPopover={false}
    />
  );
};

const renderItem = (
  item: Partial<IAccount>,
  {
    handleClick,
    modifiers,
  }: { handleClick: (event: React.MouseEvent) => void; modifiers: ItemModifiers }
) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }
  return <AccountSelectItem handleClick={handleClick} active={modifiers.active} account={item} />;
};

interface IAccountSelect {
  onChange: (account: IAccount) => void;
  initialAccount: IAccount;
  disabled?: boolean;
  fill?: boolean;
  popoverProps?: Partial<
    Omit<
      PopoverProps<DefaultPopoverTargetHTMLProps>,
      "fill" | "content" | "defaultIsOpen" | "renderTarget"
    >
  >;
}

const AccountSelect = ({
  fill = false,
  onChange,
  initialAccount,
  disabled = false,
  popoverProps,
}: IAccountSelect) => {
  const { t } = useTranslation();
  const userData = useContext(UserContext);

  const currentBudget = _.first(userData?.budget_users_connectors)?.budget;

  const [createAccount] = useCreateAccount();

  const { data, loading, error } = useQuery(ACCOUNTS_LIST, {
    variables: { budgetId: currentBudget?.id },
  });
  const { accounts } = data || {};
  const [query, setQuery] = useState("");
  const [selectedAccount, setSelectedAccount] = useState<IAccount>(initialAccount);

  const [createCategories] = useMutation(CREATE_CATEGORIES, {
    refetchQueries: [{ query: CATEGORIES }],
  });

  useEffect(() => {
    setSelectedAccount(initialAccount);
  }, [initialAccount]);

  const [confirmDialogOptions, setConfirmDialogOptions] = useState<{
    open: boolean;
    account: IAccount | null;
  }>({ open: false, account: null });

  const onSelectAccount = (account: IAccount) => {
    if (account.id) {
      setSelectedAccount(account);
      onChange(account);
    } else {
      setConfirmDialogOptions({ open: true, account });
    }
  };

  const createNewAccount = () => {
    createAccount({
      variables: {
        object: {
          ..._.omit(confirmDialogOptions.account, ["__typename"]),
          budget_id: currentBudget,
          //@ts-ignore
          user_id: userData?.user_id,
        },
      },
    })
      .then(({ data }) => {
        setSelectedAccount(data.insert_accounts_one);
        onChange(data.insert_accounts_one);
        closeDialog();
        return data;
      })
      .then((data) => {
        const { insert_accounts_one } = data;
        const categories = [
          {
            name: `Transfer to: ${insert_accounts_one.name} account`,
            budget_id: currentBudget,
            //@ts-ignore
            user_id: userData?.user_id,
          },
          {
            name: `Transfer from: ${insert_accounts_one.name} account`,
            budget_id: currentBudget,
            //@ts-ignore
            user_id: userData?.user_id,
          },
        ];
        return createCategories({ variables: { objects: categories } });
      })
      .catch(graphQlError);
  };

  const closeDialog = useCallback(() => {
    setConfirmDialogOptions({ open: false, account: null });
  }, []);

  return !_.isEmpty(accounts) ? (
    <WithLoadingSpinner isLoading={loading}>
      <div className="w-full">
        <Select
          fill={fill}
          disabled={disabled}
          query={query}
          popoverProps={popoverProps}
          // createNewItemFromQuery={createItem}
          //@ts-ignore
          // createNewItemRenderer={renderCreateItemOption}
          onQueryChange={setQuery}
          //@ts-ignore
          itemPredicate={filterAccounts}
          //@ts-ignore
          items={accounts}
          onItemSelect={onSelectAccount}
          //@ts-ignore
          itemRenderer={renderItem}
          noResults={<MenuItem disabled={true} text={t("labels.no_results")} />}
        >
          <Button
            fill={fill}
            text={
              selectedAccount ? (
                <div className="flex flex-row items-center">
                  <EntityIcon icon={selectedAccount.icon} type="account" />
                  <span className="inline-block ml-2">{selectedAccount.name}</span>
                </div>
              ) : (
                t("actions.choose_account")
              )
            }
            rightIcon="double-caret-vertical"
          />
        </Select>
      </div>
      <Dialog
        isOpen={confirmDialogOptions.open}
        title={t("confirm.new_account.title")}
        icon={IconNames.WARNING_SIGN}
        canEscapeKeyClose={false}
        canOutsideClickClose={false}
        isCloseButtonShown={false}
      >
        <DialogBody>
          <div
            dangerouslySetInnerHTML={{
              __html: t("confirm.new_account.message", {
                accountName: confirmDialogOptions.account?.name,
              }),
            }}
          />
        </DialogBody>
        <DialogFooter
          actions={
            <ButtonGroup>
              <Button intent={Intent.NONE} text={t("actions.cancel")} onClick={closeDialog} />
              <Button
                intent={Intent.PRIMARY}
                text={t("actions.create")}
                onClick={createNewAccount}
              />
            </ButtonGroup>
          }
        />
      </Dialog>
    </WithLoadingSpinner>
  ) : null;
};

export default AccountSelect;
