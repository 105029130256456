import { CapacitorCookies } from "@capacitor/core";
import _ from "lodash";
import React from "react";
import CookieConsent from "react-cookie-consent";
import PrivacyPolicyText from "./PrivacyPolicyText";

const CookieNotice = () => {
  const [showCookieNotice, setShowCookieNotice] = React.useState(false);

  React.useEffect(() => {
    const checkCookieConsent = async () => {
      const regularCookieConsent = _.trim(
        document.cookie?.split(";").find((cookie) => cookie.includes("cookiesConsent"))
      );

      setShowCookieNotice(regularCookieConsent !== "cookiesConsent=true");
    };
    checkCookieConsent();
  }, []);

  return (
    showCookieNotice && (
      <CookieConsent
        location="none"
        buttonText="Accept"
        enableDeclineButton
        overlay
        disableStyles
        overlayClasses="CookieConsent__overlay"
        containerClasses="CookieConsent__container"
        contentClasses="CookieConsent__content"
        buttonWrapperClasses="CookieConsent__buttonWrapper"
        onAccept={() => {
          CapacitorCookies.setCookie({ key: "cookiesConsent", value: "true" });
        }}
        setDeclineCookie={false}
        onDecline={() => {
          window.location.replace("https://google.com");
        }}
        cookieName="cookiesConsent"
        expires={150}
      >
        <div>
          <PrivacyPolicyText />
        </div>
      </CookieConsent>
    )
  );
};

export default CookieNotice;
