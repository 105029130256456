import gql from "graphql-tag";

export const USER = gql`
  query User($userId: String!) {
    users_by_pk(id: $userId) {
      user_id
      id
      email
      name
      role
      budget_users_connectors(
        where: { budget: { soft_deleted_at: { _is_null: true } } }
        order_by: { budget: { created_at: desc } }
      ) {
        budget {
          id
          name
          created_at
        }
      }
      subscriptions(order_by: { purchased: desc }, limit: 1) {
        purchased
        email
        subscription_end
        cancelled
        status
      }
      incomes(where: { soft_deleted_at: { _is_null: true } }) {
        id
        name
      }
      accounts(where: { soft_deleted_at: { _is_null: true } }) {
        id
        name
      }
      categories(where: { soft_deleted_at: { _is_null: true } }) {
        id
        name
      }
      settings
    }
  }
`;
