import { useLazyQuery, useMutation } from "@apollo/client";
import { NonIdealState, OverlaysProvider, Spinner } from "@blueprintjs/core";
import _ from "lodash";
import React, { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CREATE_ACCOUNT } from "../../graphql/mutations/account";
import { CREATE_BUDGET, CREATE_BUDGET_LINK } from "../../graphql/mutations/budget";
import { CREATE_BUDGET_ENTRY } from "../../graphql/mutations/budgetEntry";
import { CREATE_BUDGET_SECTION } from "../../graphql/mutations/budgetSection";
import { CREATE_CATEGORIES, CREATE_CATEGORY } from "../../graphql/mutations/category";
import { CREATE_INCOME } from "../../graphql/mutations/incomes";
import { USER } from "../../graphql/queries/user";
import { BUDGET_SECTIONS_MAP } from "../../utils/budget_samples";
import { getAccountInfoResponse } from "../accounts/Accounts";
import { UserContext } from "../WithUserContext";
import BudgetCell from "./BudgetCell";
import { BudgetItem, EmptyBudget } from "./BudgetItem";
import { DateTime } from "luxon";

const CreateBudget = () => {
  const userData = useContext(UserContext);
  const navigate = useNavigate();
  const [createBudgetStatus, setCreateBudgetStatus] = useState("idle");
  const [refetchUser] = useLazyQuery(USER, {
    variables: { userId: userData?.user_id },
  });
  const [createBudget] = useMutation(CREATE_BUDGET);
  const [createBudgetLink] = useMutation(CREATE_BUDGET_LINK);
  const [createBudgetSection] = useMutation(CREATE_BUDGET_SECTION);
  const [createCategories] = useMutation(CREATE_CATEGORIES);
  const [createCategory] = useMutation(CREATE_CATEGORY);
  const [createBudgetEntry] = useMutation(CREATE_BUDGET_ENTRY);
  const [createAccount] = useMutation(CREATE_ACCOUNT);
  const [createIncome] = useMutation(CREATE_INCOME);

  const selectedDate = DateTime.now();

  const [budgetSectionsMap, setBudgetSectionsMap] = useState(BUDGET_SECTIONS_MAP);

  const removeFromSectionsMap =
    (budgetName: string, sectionName: string) => (entryName: string) => {
      setBudgetSectionsMap((prev) => {
        const budget = _.map(prev[budgetName], (section) => {
          if (section.section_name === sectionName) {
            return {
              ...section,
              entries: _.filter(section.entries, (entry) => entry.name !== entryName),
            };
          }
          return section;
        });

        return {
          ...prev,
          [budgetName]: budget,
        };
      });
    };

  const updateInSection =
    (budgetName: string, sectionName: string) => (entryName: string, value: number) => {
      setBudgetSectionsMap((prev) => {
        const budget = _.map(prev[budgetName], (section) => {
          if (section.section_name === sectionName) {
            return {
              ...section,
              entries: _.map(section.entries, (entry) => {
                return entry.name === entryName
                  ? {
                      ...entry,
                      value,
                    }
                  : entry;
              }),
            };
          }
          return section;
        });

        return {
          ...prev,
          [budgetName]: budget,
        };
      });
    };

  const onClick = useCallback(
    (name: string) => () => {
      setCreateBudgetStatus("creating_budget");
      createBudget({
        variables: {
          object: {
            user_id: userData?.user_id,
          },
        },
      })
        .then((budget) => {
          return createBudgetLink({
            variables: {
              object: {
                budget_id: budget.data.insert_budgets_one.id,
                user_id: userData?.user_id,
              },
            },
          });
        })
        .then((budget) => {
          const { budget_id: budgetId } = budget.data.insert_budget_users_connector_one;
          setCreateBudgetStatus("creating_sections");
          return Promise.all(
            _.map(budgetSectionsMap[name], (section, idx) => {
              return createBudgetSection({
                variables: {
                  object: {
                    budget_id: budgetId,
                    section_name: t(`labels.${section.section_name}`),
                    order_number: idx + 1,
                    user_id: userData?.user_id,
                  },
                },
              });
            })
          )
            .then((budgetSectionsRes) => {
              setCreateBudgetStatus("creating_categories");
              return _.map(budgetSectionsRes, (res, idx) => {
                const { data } = res;
                const { id: budgetSectionId } = data.insert_budget_sections_one;
                return Promise.all(
                  _.map(budgetSectionsMap[name][idx].entries, (entry) => {
                    return createCategory({
                      variables: {
                        object: {
                          budget_id: budgetId,
                          user_id: userData?.user_id,
                          name: t(`labels.${entry.name}`),
                        },
                      },
                    }).then((categoryRes) => {
                      const variables = {
                        periodStart: selectedDate.startOf("month"),
                        periodEnd: selectedDate.endOf("month"),
                        object: {
                          budget_id: budgetId,
                          user_id: userData?.user_id,
                          name: t(`labels.${entry.name}`),
                          value: entry.value,
                          budget_section_id: budgetSectionId,
                          category_id: categoryRes.data.insert_categories_one.id,
                        },
                      };

                      return createBudgetEntry({
                        variables,
                      });
                    });
                  })
                );
              });
            })
            .then(() => {
              setCreateBudgetStatus("creating_accounts");
              return createAccount({
                variables: {
                  object: {
                    budget_id: budgetId,
                    user_id: userData?.user_id,
                    name: "Main Account",
                    initial_amount: 0,
                    balance: 0,
                    main_account: true,
                    currency: '{"cc":"USD","symbol":"US$","name":"United States dollar"}',
                  },
                },
              }).then(({ data }) => {
                const { data: accountRes } = getAccountInfoResponse(data);
                if (!accountRes) {
                  //TODO throw and error here, or handle it somehow
                  return null;
                }

                const categories = [
                  {
                    name: `Transfer to: ${accountRes.name} account`,
                    budget_id: budgetId,
                    user_id: userData?.user_id,
                    parent_account: accountRes.id,
                  },
                  {
                    name: `Transfer from: ${accountRes.name} account`,
                    budget_id: budgetId,
                    user_id: userData?.user_id,
                    parent_account: accountRes.id,
                  },
                ];
                return createCategories({ variables: { objects: categories } });
              });
            })
            .then(() => {
              setCreateBudgetStatus("creating_incomes");
              return createIncome({
                variables: {
                  object: {
                    budget_id: budgetId,
                    user_id: userData?.user_id,
                    name: "Main Income",
                    value: 5000,
                  },
                },
              });
            });
        })

        .then((res) => {
          console.log("Budget created successfully!");
          setCreateBudgetStatus("idle");
        })
        .then(() => {
          refetchUser();
        })
        .then(() => {
          navigate("/dashboard");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    [budgetSectionsMap]
  );

  const { t } = useTranslation();

  return (
    <OverlaysProvider>
      {createBudgetStatus !== "idle" && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50">
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-10">
            <NonIdealState icon={<Spinner />} title="Creating Budget" />
          </div>
        </div>
      )}
      <div className="max-w-6xl mx-auto mt-2 md:mt-10 mb-20">
        <h2 className="text-base md:text-xl text-center px-2 py-5">
          {t("labels.create_budget_from_template_or_an_empty_one")}
        </h2>
        <div className="mx-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {_.map(budgetSectionsMap, (sections, budgetName) => {
              return (
                <BudgetCell
                  emptyBudget={_.isEmpty(sections)}
                  key={budgetName}
                  onClick={onClick(budgetName)}
                >
                  <div className="h-full">
                    {_.isEmpty(sections) ? (
                      <EmptyBudget onClick={onClick("empty_budget")} />
                    ) : (
                      <div>
                        <h3 className="bp5-heading p-5 text-muted mt-3">
                          {t(`sample_budgets.names.${budgetName}`)}
                        </h3>
                        <div
                          className="mb-2"
                          dangerouslySetInnerHTML={{
                            __html: t(`sample_budgets.descriptions.${budgetName}`),
                          }}
                        />
                        {_.map(sections, (section) => (
                          <BudgetItem
                            key={section.section_name}
                            section={section}
                            removeFromSection={removeFromSectionsMap(
                              budgetName,
                              section.section_name
                            )}
                            updateInSection={updateInSection(budgetName, section.section_name)}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                </BudgetCell>
              );
            })}
          </div>
        </div>
      </div>
    </OverlaysProvider>
  );
};

export default CreateBudget;
